import React from "react";
import {
  Hero,
  Destekciler,
  Information,
} from "../../components/sections/atolyemiz";

const Atolyemiz = () => {
  return (
    <>
      <Hero />
      <Destekciler />
      <Information />
    </>
  );
};

export default Atolyemiz;
