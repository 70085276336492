import React, { useContext } from "react";
import { Parallax } from "react-parallax";
import background from "../../../images/images/background.svg";
import { AtolyemizDataContext } from "../../../context";

const Hero = () => {
  const data = useContext(AtolyemizDataContext);

  return (
    <Parallax bgImage={background} reverse strength={200}>
      <section id="atolyemiz_hero">
        <div className="about_project">
          <h1>{data?.giris_baslik}</h1>
          <p>{data?.giris_aciklama_paragraf_1}</p>
          <p>{data?.giris_aciklama_paragraf_2}</p>
        </div>
      </section>
    </Parallax>
  );
};

export default Hero;
