import React, { useContext } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import TextRowWithAsset from "../../../components/TextRowWithAsset";
import SectionHeader from "../../../components/Headers/SectionHeader";
import { AtolyemizDataContext, GeneralDataContext } from "../../../context";
import { getImage } from "gatsby-plugin-image";

const Information = () => {
  const generalData = useContext(GeneralDataContext);
  const atolyeData = useContext(AtolyemizDataContext);

  return (
    <section id="atolyemiz_information">
      <TextRowWithAsset
        asset={
          atolyeData?.resimli_satir_1.resim?.childImageSharp.gatsbyImageData
        }
        heading={atolyeData?.resimli_satir_1.baslik}
        text={atolyeData?.resimli_satir_1.aciklama}
      />

      <SectionHeader text="Galeri" />
      <div className="carousel_container">
        {atolyeData?.galeri && (
          <Carousel
            className="carousel"
            autoPlay={true}
            showStatus={false}
            infiniteLoop={true}
            interval={10000}
            showThumbs={false}
          >
            {atolyeData?.galeri.map(image => {
              return (
                <img
                  src={image?.url}
                  style={{
                    backgroundColor: "lightgray",
                  }}
                  key={image?.id}
                  alt="Atölye görsellerinden birisi"
                />
              );
            })}
          </Carousel>
        )}
      </div>

      <TextRowWithAsset
        asset={getImage(atolyeData?.resimli_satir_2?.resim)}
        heading={atolyeData?.resimli_satir_2.baslik}
        text={atolyeData?.resimli_satir_2.aciklama}
        reverse
        textCarouselData={generalData?.ekip}
      />
    </section>
  );
};

export default Information;
