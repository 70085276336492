import React, { useContext } from "react";
import { StaticImage } from "gatsby-plugin-image";
import TextCarousel from "../../../components/TextCarousel";
import { AtolyemizDataContext, GeneralDataContext } from "../../../context";

const Destekciler = () => {
  const data = useContext(GeneralDataContext);
  const atolyeData = useContext(AtolyemizDataContext);

  return (
    <section id="atolyemiz_destekciler">
      <div className="logo_container">
        <StaticImage
          src="../../../images/logos/1-meb-logo.png"
          width={180}
          alt="Milli Eğitim Bakanlığı logosu"
          placeholder="blurred"
        />
        <StaticImage
          src="../../../images/logos/2-ankaraka.png"
          width={180}
          alt="Ankara Kalkınma Ajansı logosu"
          placeholder="blurred"
        />
        <StaticImage
          src="../../../images/logos/4-ostim-logo.png"
          width={180}
          alt="Ostim logosu"
          placeholder="blurred"
        />
        <StaticImage
          src="../../../images/logos/5-sanayi-bakanlık-logo.png"
          width={180}
          alt="Sanayi Bakanlığı logosu"
          placeholder="blurred"
        />
      </div>
      <TextCarousel data={data?.destekciler} />
      <p>{atolyeData?.destekciler_tesekkur_yazisi}</p>
    </section>
  );
};

export default Destekciler;
